.icon {
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3));
  }
  
  .name {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);

      font-size: lg;

      @media (min-width: 94) {
        background-color: red;
        font-size: xs;
      }
  }