.container {
    background-color: #11284b;
    background-size: cover;
}

.inner {
    
    display: flex;
    justify-content: space-between;
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  }
  
  .content {
    
    max-width: rem(480px);
    margin-left: calc(var(--mantine-spacing-xl) * 3);
  
    @media (max-width: $mantine-breakpoint-md) {
      max-width: 100%;
      margin-left: 0;
    }
  }
  
 
  .title {
    color: light-dark(var(--mantine-color-white), var(--mantine-color-black));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(44px);
    line-height: 1.2;
    font-weight: 900;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(28px);
    }
  }

  .expertise {
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(22px);
    line-height: 1.2;
    font-weight: 500;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(24px);
    }
  }
  
  .control {
    @media (max-width: $mantine-breakpoint-xs) {
      flex: 1;
    }
  }
  
  .image {
    width: rem(376px);
    height: rem(356px);
  
    @media (max-width: $mantine-breakpoint-md) {
      display: none;
    }
  }
  
  .avatar {
    border: rem(1px) solid var(--mantine-color-body);
  }

  .highlight {
    position: relative;
    background-color: var(--mantine-color-blue-light);
    border-radius: var(--mantine-radius-sm);
    padding: rem(4px) rem(12px);
  }
