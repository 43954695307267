.container {
    background-color: #11284b;
    background-size: cover;
    background-position: center;
    height: 500px;
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}
  
.content {
    max-width: rem(500px);
    margin-left: calc(var(--mantine-spacing-xl) * 3);
  
    @media (max-width: $mantine-breakpoint-md) {
      max-width: 100%;
      margin-left: 0;
    }
  }
  
 
  .title {
    color: light-dark(var(--mantine-color-white), var(--mantine-color-black));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(44px);
    line-height: 1.2;
    font-weight: 900;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(28px);
    }
  }

  .expertise {
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(15px);
    line-height: 1.2;
    font-weight: 400;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(24px);
    }
  }
  
  .control {
    @media (max-width: $mantine-breakpoint-xs) {
      flex: 1;
    }
  }

  .icon {
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-white));
  }

  .mycarousel {
    inset-inline-start: 0;
    inset-inline-end: 0;
  }