.title {
    font-family: Greycliff CF, var(--mantine-font-family);
    font-size: rem(34px);
    font-weight: 750;
    width: 100%;
    color: var(--mantine-color-gray-6);
    padding-bottom: calc(var(--mantine-spacing-xl) * 1);

    @media (max-width: $mantine-breakpoint-sm) {
      font-size: rem(24px);
    }
  }

  .container {
    background-color: var(--mantine-color-gray-0);    
  }

  .timelineTitle {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(24px);
    line-height: 1;
    font-weight: 600;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(18px);
    }
  }