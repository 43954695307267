.timelineTitle {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(24px);
    line-height: 1;
    font-weight: 600;
  
    @media (max-width: $mantine-breakpoint-xs) {
      font-size: rem(18px);
    }
  }

  .about{
    font-family:
    Greycliff CF,
    var(--mantine-font-family);
    font-size: rem(14px);
    line-height: 1;
    font-weight: 500;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(12px);
  }
  }